import Vue from "vue";
import mApp from "./views/mobile/App.vue";
import wApp from "./views/web/App.vue";
import router from "./router";
import store from "./store";
Vue.config.devtools = true;
Vue.config.productionTip = false;
import request from "./request";
Vue.prototype.$http = request;

function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isHarmonyOS = /harmonyos/i.test(userAgent);

    // 判断设备是否为手机
    if (isHarmonyOS) {
        return /mobile/i.test(userAgent) || window.innerWidth <= 768; // 可根据需要调整宽度阈值
    }

    // 检测其他常见移动设备
    return /android|iphone|ipad|ipod|windows phone|blackberry|iemobile|mobile/i.test(userAgent);
}

Vue.prototype.$isMobile = isMobile;
if (!isMobile()) {
    document.body.classList.add("web");
}
import "amfe-flexible";
new Vue({
    router,
    store,
    render: (h) => h(isMobile() ? mApp : wApp)
}).$mount("#app");
