<template>
    <div class="popup-overlay" v-if="isVisible" @click.self="closePopup">
        <transition name="slide-up" @after-leave="onAfterLeave">
            <div class="popup-content" v-if="visible">
                <div class="popup-content-top" v-if="!noContentTop" @click="closePopup"></div>
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        noContentTop: {
            default: false
        }
    },
    data() {
        return {
            visible: false,
            isVisible: false
        };
    },
    methods: {
        openPopup() {
            this.isVisible = true; // 先显示overlay
            this.$nextTick(() => {
                this.visible = true; // 然后再显示弹窗
            });
        },
        closePopup() {
            this.visible = false; // 先开始关闭动画
        },
        onAfterLeave() {
            this.isVisible = false; // 动画结束后隐藏overlay
        }
    },
    watch: {
        isVisible(v) {
            if (v) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "auto";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 9999;
}

.popup-content {
    background: #000;
    border-radius: 14px 14px 0 0;
    width: 100vw;
    padding: 16px;
    .popup-content-top {
        width: 32px;
        height: 3px;
        border-radius: 10px;
        background: #807d7d;
        margin: 0 auto;
    }
}

.slide-up-enter, .slide-up-leave-to /* .slide-up-leave-active in <2.1.8 */ {
    transform: translateY(100%);
    opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.3s ease;
}
</style>
