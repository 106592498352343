<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
        <Unlock ref="Unlock" :noContentTop="true" />
        <Coupon ref="Coupon" />
        <Congratulations ref="Congratulations" />
        <Login ref="Login" />
    </div>
</template>
<script>
import Unlock from "./components/Unlock/index.vue";
import Coupon from "./components/Coupon/index.vue";
import Congratulations from "./components/Congratulations/index.vue";
import Login from "./components/Logins/index.vue";
export default {
    components: {
        Unlock,
        Coupon,
        Congratulations,
        Login
    },
    provide() {
        return {
            openUnlock: () => {
                this.$refs.Unlock.open();
            },
            openCoupon: () => {
                this.$refs.Coupon.open();
            },
            openCongratulations: (openlg) => {
                this.$refs.Congratulations.open(openlg);
            },
            openLogin: () => {
                this.$refs.Login.open();
            }
        };
    },
    created() {
        this.$http.post("api/auth/login", {}).then((res) => {
            if (res.success && res.data) {
                localStorage.setItem("token", res.data.token);
            }
            console.log(res);
        });
    },
    async mounted() {
        console.log(this.$route);

        // this.$refs.Coupon.open();
        // this.$http
        //     .post("/api/stripe/create-subscription", {
        //         email: "11@11.com",
        //         paymentMethodId: "string",
        //         priceId: "string"
        //     })
        //     .then((res) => {
        //         console.log(res);
        //     });
    }
};
</script>
<style lang="scss">
html {
    font-size: 100px;
}
body,
html {
    padding: 0;
    margin: 0;
    background: #000;
}
.web {
    overflow: hidden;
}
#app {
    font-family: "微软雅黑";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
}
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
p {
    margin: 0;
}
* {
    box-sizing: border-box;
    user-select: none;
}
</style>
