import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/mobile/home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: home,
        meta: {
            keepAlive: true
        }
    },
    {
        path: "/followList",
        name: "followList",
        component: () => import(/* webpackChunkName: "about" */ "../views/mobile/followList.vue")
    },
    {
        path: "/play",
        name: "play",
        component: () => import(/* webpackChunkName: "about" */ "../views/mobile/play.vue")
    },
    {
        path: "/pay",
        name: "pay",
        component: () => import(/* webpackChunkName: "about" */ "../views/mobile/pay.vue")
    },
    {
        path: "/web",
        name: "web",
        component: () => import(/* webpackChunkName: "about" */ "../views/web/index.vue"),
        children: [
            {
                path: "/",
                redirect: "/web/index"
            },
            {
                path: "index",
                name: "index",
                component: () => import(/* webpackChunkName: "about" */ "../views/web/home.vue")
            },
            {
                path: "browse",
                name: "browse",
                component: () => import(/* webpackChunkName: "about" */ "../views/web/browse.vue")
            },
            {
                path: "myList",
                name: "myList",
                component: () => import(/* webpackChunkName: "about" */ "../views/web/myList.vue")
            },
            {
                path: "play",
                name: "wplay",
                component: () => import(/* webpackChunkName: "about" */ "../views/web/play.vue")
            }
        ]
    }
];

const router = new VueRouter({
    mode: "history",
    routes
});

export default router;
