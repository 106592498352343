<template>
    <bottom-popup ref="popup" class="Login-popup" v-bind="$attrs" :noContentTop="true">
        <div class="Login">
            <div class="close" @click="close"></div>
            <div class="content">
                <h1>Please register an account</h1>
                <p>This will save your watching history.</p>
                <div class="form" ref="form">
                    <div class="EmailD">
                        <input class="Email" type="text" placeholder="Email" maxlength="50" />
                    </div>
                    <div class="passwordD">
                        <input type="password" placeholder="Password" maxlength="20" />
                    </div>
                </div>
            </div>
            <div class="tips">By continuing with any option, you agree to our <span>Terms of Service</span> and have read the <span>Privacy Policy</span></div>
            <div class="SignUp" @click="SignUp">Sign up</div>
            <div class="Login" @click="close">Log in</div>
        </div>
    </bottom-popup>
</template>

<script>
import BottomPopup from "../BottomPopup.vue";
export default {
    name: "Login",
    components: {
        BottomPopup
    },
    data() {
        return {};
    },
    methods: {
        open() {
            this.$refs.popup.openPopup();
        },
        close() {
            this.$refs.popup.closePopup();
        },
        itemClick(item) {
            this.current = item;
        },
        formValidate() {
            return new Promise((r) => {
                let arr = [];
                this.$refs.form.childNodes.forEach((c) => {
                    const input = c.childNodes[0];
                    if (input.value) {
                        c.className = c.className.replace(" no", "");
                        if (input.className === "Email" && input.value.indexOf("@") < 0) {
                            c.className = c.className + " no";
                            arr.push(false);
                        }
                        arr.push(true);
                    } else {
                        if (c.className.indexOf("no") < 0) {
                            c.className = c.className + " no";
                        }
                        arr.push(false);
                    }
                });
                if (arr.every((a) => a)) {
                    r(true);
                }
            });
        },
        SignUp() {
            this.formValidate(() => {
                console.log(123);
            });
        },
        CardPayment() {
            this.close();
            this.$router.push({ name: "pay" });
        }
    }
};
</script>

<style lang="scss" scoped>
.Login-popup {
    ::v-deep .popup-content {
        width: 480px;
        background: #1d1d1d;
        padding: 0 48px;
        position: relative;
    }
    .Login {
        box-sizing: content-box;
        width: 100%;
        height: 560px;
        overflow: hidden;
        .close {
            position: absolute;
            right: 24px;
            top: 24px;
            background: url("../../../../images/close.png") 0 0 / 24px no-repeat;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
        .content {
            h1 {
                font-size: 24px;
                line-height: 34px;
                font-weight: normal;
                margin: 0;
                margin-top: 64px;
            }
            p {
                font-size: 14px;
                line-height: 20px;
                margin-top: 8px;
                margin-bottom: 40px;
            }
            .form {
                & > div {
                    & + div {
                        margin-top: 40px;
                    }
                }
                input {
                    width: 100%;
                    border: none;
                    background: transparent;
                    height: 56px;
                    border: 2px solid #fff;
                    border-radius: 12px;
                    outline: none;
                    color: #fff;
                    padding: 13px 16px;
                    font-size: 16px;
                }
            }
        }

        .tips {
            line-height: 20px;
            font-size: 14px;
            color: #9ba2ae;
            margin-top: 40px;
            span {
                color: #1065e1;
            }
        }

        .SignUp {
            background: #d45252;
            height: 44px;
            border-radius: 8px;
            text-align: center;
            line-height: 44px;
            font-size: 16px;
            margin-top: 12px;
            cursor: pointer;
        }

        .Login {
            height: 44px;
            border-radius: 8px;
            text-align: center;
            line-height: 44px;
            font-size: 13px;
            margin-top: 15px;
            color: #ffffff80;
            cursor: pointer;
        }

        .no {
            position: relative;
            input {
                border: 2px solid #d45252 !important;
            }
            &::after {
                content: "Please check";
                display: block;
                color: #d45252;
                font-size: 14px;
                position: absolute;
                left: 0;
                bottom: -26px;
            }
        }
        .EmailD.no {
            &::after {
                content: "Please enter a valid email address";
            }
        }
    }
}
</style>
